<template>
  <div>
    <div class="page _mb-display-none">
      <div class="pc-page">
        <div class="search-box">
          <img class="icon" src="@/assets/image/search-icon.png" alt="" />
          <input v-model="expresssn" class="input" type="text" placeholder="请输入快递单号" />
          <div @click="queryExpress" class="btn">查询</div>
        </div>
        <div class="list">
          <div class="item" :class="{ active: index == 0 }" v-for="(item, index) in track" :key="index">
            <!-- <div class="title">派送中</div> -->
            <div class="content">
              <div class="time">{{ item.track_date }}</div>
              <div class="msg">
                {{ item.detail }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="track.length < 1" class="empty-box">
          <img class="img" src="@/assets/image/search-empty-bg.png" alt="" />
          <div class="text">暂无物流进度信息</div>
        </div>
      </div>
    </div>

    <div class="_mb-display-block _pc-display-none _mb-page">
      <div class="mb-search">
        <img class="icon" src="@/assets/image/search-icon.png" alt="" />
        <input v-model="expresssn" class="input" type="text" placeholder="请输入快递单号" />
        <div @click="queryExpress" class="btn">查询
        </div>
      </div>
      <div class="mb-list">
        <div class="item" :class="{ active: index == 0 }" v-for="(item, index) in track" :key="index">
          <!-- <div class="title">派送中</div> -->
          <div class="content">
            <div class="time">{{ item.track_date }}</div>
            <div class="msg">
              {{ item.detail }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="track.length < 1" class="mb-empty">
        <img class="img" src="@/assets/image/mb-search-empty-bg.png" alt="">
        <div class="text">暂无物流进度信息</div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "search",
  data() {
    return {
      expresssn: '',
      track: []
    };
  },
  created() { },
  methods: {
    queryExpress() {
      this.$axios.post('aboard_search', { express_sn: this.expresssn }).then(res => {
        this.track = res.data.data
      })
    }
  },
};
</script>
  
<style scoped>
.page {
  background: #fff;
}

.pc-page {
  width: 67.71vw;
  margin: 0 auto;
  padding-top: 3.49vw;
}

.search-box {
  width: 100%;
  padding: 1.09vw 1.09vw 1.09vw 1.67vw;
  background: #f5f5f5;
  border-radius: 0.73vw;
  display: flex;
  align-items: center;
}

.search-box .icon {
  width: 1.46vw;
}

.search-box .input {
  flex: 1;
  height: 100%;
  font-size: 1.25vw;
  color: #333333;
  padding: 0 1.3vw;
  background: transparent;
  resize: none;
  outline: none;
  border: 0;
}

.search-box .btn {
  width: 7.66vw;
  height: 3.54vw;
  background: #d92621;
  border-radius: 0.63vw;
  color: #ffffff;
  line-height: 3.54vw;
  font-size: 1.25vw;
  text-align: center;
  cursor: pointer;
}

.list {
  margin-top: 3.33vw;
  width: 100%;
  padding-bottom: 3.85vw;
}

.list .item {
  width: 100%;
  display: flex;
}

.list .item .title {
  font-size: 1.04vw;
  color: #333333;
}

.list .item .content {
  flex: 1;
  margin-left: 1.98vw;
  padding-left: 1.67vw;
  padding-bottom: 4.06vw;
  display: flex;
  border-left: 2px dashed #afafaf;
  position: relative;
}

.list .active .title {
  color: #d92621;
  font-weight: bold;
}

.list .active .content::before {
  left: -0.74vw !important;
  width: 1.46vw !important;
  height: 1.46vw !important;
  background: #d92621 !important;
}

.list .item .content::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -0.46vw;
  width: 0.89vw;
  height: 0.89vw;
  background: #afafaf;
  border-radius: 0.1vw;
  transform: rotateZ(45deg);
}

.list .item:last-child .content {
  border-color: #fff;
  padding-bottom: 0;
}

.list .item .content .time {
  font-size: 1.04vw;
  color: #333333;
}

.list .item .content .msg {
  flex: 1;
  padding-left: 1.67vw;
  font-size: 1.04vw;
  color: #333333;
}

.empty-box {
  margin: 9.64vw 0 13.39vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-box .img {
  width: 16.15vw;
}

.empty-box .text {
  font-size: 1.04vw;
  color: #333333;
  margin-top: 1.46vw;
}

._mb-page {
  background: #f7f7f7;
  padding: 6.27vw 3.73vw 0;
}

.mb-search {
  width: 100%;
  padding: 2.53vw 2.53vw 2.53vw 3.73vw;
  background: #fff;
  border-radius: 1.2vw;
  display: flex;
  align-items: center;
}

.mb-search .icon {
  width: 3.73vw;
}

.mb-search .input {
  flex: 1;
  padding: 0 2.67vw;
  font-size: 3.73vw;
  color: #333333;
  background: transparent;
  resize: none;
  outline: none;
  border: 0;
}

.mb-search .btn {
  width: 16.67vw;
  height: 9.07vw;
  background: #d92621;
  border-radius: 1.6vw;
  font-size: 3.73vw;
  color: #ffffff;
  line-height: 9.07vw;
  text-align: center;
}

.mb-list {
  margin-top: 11.33vw;
  width: 100%;
  padding-bottom: 13.73vw;
}

.mb-list .item {
  width: 100%;
  display: flex;
}

.mb-list .item .title {
  font-size: 3.73vw;
  color: #333;
}

.mb-list .item .content {
  margin-left: 4.53vw;
  padding-left: 4.93vw;
  border-left: 0.27vw dashed #afafaf;
  position: relative;
  flex: 1;
  padding-bottom: 8.4vw;
}

.mb-list .item:last-child .content {
  padding-bottom: 0;
  border-color: #f7f7f7;
}

.mb-list .item .content::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -1.2vw;
  width: 2.4vw;
  height: 2.4vw;
  transform: rotateZ(45deg);
  background: #afafaf;
  border-radius: 0.27vw;
}

.mb-list .active .title {
  color: #D92621;
  font-weight: bold;
}

.mb-list .active .content::before {
  left: -1.87vw !important;
  width: 3.73vw !important;
  height: 3.73vw !important;
  background: #D92621 !important;
}

.mb-list .item .content .time {
  font-size: 3.73vw;
  color: #333;
}

.mb-list .item .content .msg {
  font-size: 3.73vw;
  color: #333;
  margin-top: 3.07vw;
  line-height: 5.87vw;
}

.mb-empty {
  padding: 18.13vw 0 29.73vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mb-empty .img {
  width: 54.40vw;
}

.mb-empty .text {
  font-size: 3.73vw;
  color: #333;
  margin-top: 5.07vw;
}
</style>